import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import GeneralFallBack from "components/general/GeneralFallBack/GeneralFallBack.js";
import UserStore from "contexts/user";
import Domains from "components/domains";
import { isTokenExpired } from "utils/auth/auth";
import { logout } from "utils/auth/auth";

// Lazy loaded components
const AdminLayout = React.lazy(() => import("layouts/Admin"));
const HagrLayout = React.lazy(() => import("layouts/Hagr"));
const ClientLayout = React.lazy(() => import("layouts/Client"));
const SellCrops = React.lazy(() => import("layouts/SellCrops/SellCrops"));
const LinkTree = React.lazy(() => import("layouts/LinkTree/LinkTree"));
const SellCropsUser = React.lazy(() => import("layouts/SellCropsUser/SellCrops"));
const LoginSellCropsUser = React.lazy(() => import("layouts/loginSellCropsUser/loginSellCropsUser"));
const BuyCrops = React.lazy(() => import("layouts/BuyCrops/BuyCrops"));
const BuyCropsEnglish = React.lazy(() => import("layouts/BuyCropsEnglish/BuyCrops"));
const TraceCert = React.lazy(() => import("layouts/traceCertificate/Index"));
const PublicLayout = React.lazy(() => import("layouts/Public"));

const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  return token && user && !isTokenExpired(token);
};

// A wrapper component to handle route protection using useNavigate
const ProtectedRoute = ({ role, children, redirectTo = "/login" }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || isTokenExpired(token)) {
      logout();
    } else if (!isAuthenticated()) {
      navigate(redirectTo, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, navigate, redirectTo]);

  return isAuthenticated() ? children : null;
};

// Component to handle domain routing
const domainSubmitted = localStorage.getItem("domainDetails");
const HomeRoute = () => {
  // If user is authenticated, only render the PublicLayout (block Domains)
  if (isAuthenticated()) {
    return <PublicLayout />;
  }

  return !domainSubmitted ? <Domains /> : <PublicLayout />;
};

const App = () => {
  return (
    <Suspense fallback={<GeneralFallBack />}>
      <UserStore>
        <Routes>
          {/* Root route for Domains or PublicLayout */}
          <Route path='/*' element={<HomeRoute />} />

          {/* Admin protected route */}
          <Route
            path='/admin/*'
            element={
              <ProtectedRoute role='admin'>
                <AdminLayout />
              </ProtectedRoute>
            }
          />

          {/* Hagr protected route */}
          <Route
            path='/hagr/*'
            element={
              <ProtectedRoute role='hagr'>
                <HagrLayout />
              </ProtectedRoute>
            }
          />

          {/* Client protected route */}
          <Route
            path='/client/*'
            element={
              <ProtectedRoute role='client'>
                <ClientLayout />
              </ProtectedRoute>
            }
          />

          {/* Sell Crops User protected route */}
          <Route
            path='/MahaseelMasr-sellcrops-user/*'
            element={
              <ProtectedRoute role='MahaseelMasr-sellcrops-user' redirectTo='/loginSellCropsUser'>
                <SellCropsUser />
              </ProtectedRoute>
            }
          />

          {/* Unprotected routes */}
          <Route path='/loginSellCropsUser/*' element={<LoginSellCropsUser />} />
          <Route path='/MahaseelMasr-sellcrops/*' element={<SellCrops />} />
          <Route path='/links/*' element={<LinkTree />} />
          <Route path='/MahaseelMasr-buycrops/*' element={<BuyCrops />} />
          <Route path='/MahaseelMasr-buycrops-worldwide/*' element={<BuyCropsEnglish />} />
          <Route path='/traceCert/:code' element={<TraceCert />} />

          {/* Catch-all for undefined routes */}
          <Route path='*' element={<PublicLayout />} />
        </Routes>
      </UserStore>
    </Suspense>
  );
};

export default App;
